<template>
  <div>
    <V-form @submit.prevent="submitResponse">
      <p class="mb-3">
        A weak base with a
        <stemble-latex content="$\text{K}_\text{b}$" />
        value of
        <number-value :value="Kb" />
        is dissolved in water. If the concentration of the weak base is
        <number-value :value="concentration" unit="\text{M,}" />
        what is the resulting
        <stemble-latex content="$\text{pH}$" />
        of the solution?
      </p>

      <calculation-input
        v-model="inputs.pH"
        prepend-text="$\text{pH:}$"
        :disabled="!allowEditing"
      />
    </V-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import CalculationInput from '../inputs/CalculationInput';
import NumberValue from '../NumberValue';
import StembleLatex from '@/tasks/components/StembleLatex';

export default {
  name: 'Question142',
  components: {
    StembleLatex,
    CalculationInput,
    NumberValue,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      // Put a list of inputs or computed properties here to be sent for marking
      inputs: {
        pH: null,
      },
    };
  },
  computed: {
    concentration() {
      return this.taskState.getValueBySymbol('concentration').content;
    },
    Kb() {
      return this.taskState.getValueBySymbol('Kb').content;
    },
  },
};
</script>
